<template>
  <div>
    <h2 class="headline--lg text-bold mb-32 text-center">Update User Information</h2>

    <form @submit.prevent="onForceUpdateProfile">
      <div class="form-group">
        <label class="form-label">Full Name</label>
        <input
          type="text"
          :class="['form-control', { 'form-control--error': $v.params.fullName.$anyError }]"
          v-model="$v.params.fullName.$model"
        />
        <p v-if="$v.params.fullName.$anyError" class="form-error-text">
          <span v-if="!$v.params.fullName.required">fullName can't be blank</span>
        </p>
      </div>

      <div class="form-group">
        <label class="form-label">Email</label>
        <input
          type="text"
          :class="['form-control', { 'form-control--error': $v.params.email.$anyError }]"
          v-model="$v.params.email.$model"
        />
        <p v-if="$v.params.email.$anyError" class="form-error-text">
          <span v-if="!$v.params.email.required">email can't be blank</span>
          <span v-if="!$v.params.email.email">email invalid</span>
        </p>
      </div>

      <div class="form-group">
        <label class="form-label">Role</label>
        <br />
        <label class="form-radio">
          <input type="radio" value="2" v-model="params.role" /> Admin
          <span></span>
        </label>

        <label class="form-radio">
          <input type="radio" value="3" v-model="params.role" /> Member
          <span></span>
        </label>
      </div>

      <div class="form-group">
        <button class="btn btn--primary btn--full">Update</button>
      </div>
    </form>
  </div>
</template>

<script>
import forceUpdateUserProfileFormValidator from '@/validations/force-update-user-profile-form'

export default {
  name: 'ForceUpdateBoUserForm',

  data() {
    return {
      params: {
        email: '',
        fullName: '',
        username: '',
        phone: '',
        contryCode: '',
        role: ''
      }
    }
  },

  props: {
    user: {
      type: Object,
      require: true
    }
  },

  mixins: [forceUpdateUserProfileFormValidator],

  methods: {
    onForceUpdateProfile() {
      this.$v.$touch()
      if (this.isFormValid) this.$emit('on-force-update-profile', this.params)
    }
  },

  created() {
    Object.keys(this.params).forEach(key => {
      this.params[key] = this.user[key]
    })
  },

  watch: {
    user: {
      handler(newVal) {
        Object.keys(this.params).forEach(key => {
          this.params[key] = newVal[key]
        })
      },
      deep: true
    }
  }
}
</script>
